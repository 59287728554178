import React from 'react';

import UserCircle from '../../assets/user_circle.svg';
import PasswordIcon from '../../assets/password.svg';
import ShieldSheckeredIcon from '../../assets/shield_sheckered.svg';

export interface Feature {
  keyword: string;
  title: React.ReactNode;
  text: string | React.ReactNode;
  icon: React.ReactNode;
}

const features: Feature[] = [
  {
    keyword: 'userData',
    title: 'A user-first approach',
    text: 'Fidel API puts your users first through our consent-based approach. We’ll also never  ask your users for bank login details or sensitive PII.',
    icon: <UserCircle />,
  },
  {
    keyword: 'integration',
    title: 'Card number tokenization',
    text: 'Fidel API’s multi-network tokenization engine supports all cards through a single integration.',
    icon: <PasswordIcon />,
  },
  {
    keyword: 'compliance',
    title: 'Streamlined compliance',
    text: 'Card Enrollment SDKs enable you to securely enroll users without managing compliance requirements.',
    icon: <ShieldSheckeredIcon />,
  },
];

export default features;
