import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { breakpoints } from '../../../../components/styled/variables';

export const AddonImage = styled(GatsbyImage)<{
  width: string;
}>`
  margin: 80px 0px;
  max-width: ${props => props.width};
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    width: calc(100% - 40px);
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin: 20px 0;
  }
`;
