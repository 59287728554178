import React from 'react';
import { Block, Flex, Heading, Section } from '../../../components/styled';
import { colours } from '../../../components/styled/variables';
import { Feature } from './features/security-features';
import { FeatureText, FeatureTitle, StyledGLobe } from './styled';

export default function SecurityFeatures({
  features,
}: {
  features: Feature[];
}) {
  return (
    <Section
      maxWidth={1248}
      bgColor={colours.blue}
      style={{ position: 'relative' }}
    >
      <StyledGLobe />
      <Block style={{ zIndex: 1 }} width="1088px" margin="auto" relative>
        <Flex columns={1} justifyContent="center">
          <Flex flow="row wrap">
            <Block maxWidth="330px">
              <h4 style={{ color: colours.white }}>Your trusted partner</h4>
              <Heading
                size={48}
                weight={900}
                lineHeight="60px"
                color={colours.white}
              >
                Industry leaders in data security
              </Heading>
            </Block>

            <Block>
              <Flex columns={3}>
                {features.map(({ keyword, title, text, icon }: Feature) => (
                  <div style={{ color: colours.white }} key={keyword}>
                    <Block margin="30px 0 30px">
                      {icon}
                      <FeatureTitle>{title}</FeatureTitle>
                      <FeatureText style={{ color: colours.white }}>
                        {text}
                      </FeatureText>
                    </Block>
                  </div>
                ))}
              </Flex>
            </Block>
          </Flex>
        </Flex>
      </Block>
    </Section>
  );
}
