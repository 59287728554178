import styled from 'styled-components';
import { breakpoints } from '../../../../components/styled/variables';
import Globe from '../../assets/globe.svg';

export const StyledGLobe = styled(Globe)`
  position: absolute;
  z-index: 0;
  top: 1px;

  @media (min-width: ${breakpoints.tablet}) {
    right: 0;
  }
`;

export const FeatureTitle = styled.h3`
  font-weight: 900;
  margin: 14px 0 8px;
  font-size: 18px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
  }
`;

export const FeatureText = styled.p`
  margin-top: 0;
`;
