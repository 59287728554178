import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Layout, SEO } from '../../../layout';

import Lightning from '../assets/lightning.svg';
import AppWindow from '../assets/app_window.svg';
import ShieldPlus from '../assets/shield_plus.svg';
import DashboardFeatures from '../common/components/dashboard-features';
import SideBySideSection from '../../../components/side-by-side-section';
import { colours } from '../../../components/styled/variables';
import { DevelopersList } from '../../index/styled';
import { CodeWindow } from '../../../components';
import tsObject from '../code-blocks/ts-object';
import { CTAContainer, FeatureCTA } from '../../../components/styled';
import ArrowIcon from '../../../static/icons/arrow.svg';
import { AddonImage } from './styled';
import HeroSection, { HeroFeature } from '../common/components/hero-section';
import SecurityFeatures from '../security/security-features';
import features from '../security/features/security-features';

export default function TransactionStream() {
  const { heroImg, heroImgMobile, cardEnrollImg } = useStaticQuery(assetsQuery);

  const heroImage = heroImg.childImageSharp.gatsbyImageData.images.fallback.src;
  const heroImageMobile =
    heroImgMobile.childImageSharp.gatsbyImageData.images.fallback.src;
  const cardEnrollImage = cardEnrollImg.childImageSharp.gatsbyImageData;

  const heroFeatures: HeroFeature[] = [
    {
      icon: <Lightning />,
      title: 'Every transaction, in real time',
      description:
        'Ingest and build on top of a stream of payment events, pushed in real time, to create powerful user experiences.',
    },
    {
      icon: <AppWindow />,
      title: 'A simple integration',
      description:
        'Get up and running faster with Fidel API. Bring your program to market in a matter of days with a seamless, reliable API.',
    },
    {
      icon: <ShieldPlus />,
      title: 'User-security first',
      description:
        'Protect your customers with a consent-based approach. Safeguard user information with Fidel API’s secure tokenization engine.',
    },
  ];

  return (
    <Layout>
      <SEO
        title="Transaction Stream API"
        description="Get payment events from all transactions made on any enrolled card in real time. Build on top of a full stream of standardized, clean transaction records."
      />
      <HeroSection
        id="productStream"
        title="Transaction Stream API"
        subtitle="Get payment events from all transactions made on any enrolled card in real time. Build on top of a full stream of standardized, clean transaction records."
        images={{
          desktop: heroImage,
          mobile: heroImageMobile,
        }}
        features={heroFeatures}
      />
      <DashboardFeatures
        title="A complete suite"
        subtitle="Build on top of the Transaction Stream API to create more valuable, engaging experiences for your customers. Get the tools you need to effectively manage and scale your program."
        programType="transaction-stream"
      />
      <SideBySideSection
        bgColor={colours.primary}
        sideContentPosition="left"
        inverted
        heading="Data for developers"
        surtitle="Clean code"
        flexAlignItems="stretch"
        content={
          <>
            <p style={{ color: colours.white }}>
              Trigger event-based experiences at the point of purchase. Build on
              clean authorization, clearing and refund messaging for every
              transaction on an enrolled card.
            </p>

            <DevelopersList style={{ fontSize: '14px' }}>
              <li>+ Brand name</li>
              <li>+ Brand descriptor</li>
              <li>+ Zip code / state / country</li>
              <li>+ Date and time</li>
              <li>+ Original purchase currency and amount</li>
              <li>+ Billed currency and amount</li>
              <li>+ Auth code</li>
              <li>+ Merchant category code</li>
            </DevelopersList>
            <CTAContainer>
              <FeatureCTA
                id="dashboardSignUp-productStream-codeSection"
                as="a"
                href="https://dashboard.fidel.uk/sign-up"
                rel="noopener noreferrer"
                target="_blank"
                variant="secondary"
              >
                Get API key <ArrowIcon />
              </FeatureCTA>
              <FeatureCTA as="a" href="/docs" variant="border">
                Go to docs
              </FeatureCTA>
            </CTAContainer>
          </>
        }
        sideContent={
          <CodeWindow title="Fidel API Transaction Object" value={tsObject} />
        }
      />
      <SecurityFeatures features={features} />
      <SideBySideSection
        bgColor={colours.lightGrey2}
        sideContentPosition="left"
        heading="Connect securely"
        surtitle="Do more with add-ons"
        content={
          <p>
            Use the Verified Enrollment SDK with the Transaction Stream API to
            give your users a painless and secure card enrollment experience.
          </p>
        }
        sideContent={
          <AddonImage
            image={cardEnrollImage}
            alt="Card enroll image"
            width="737px"
          />
        }
      />
    </Layout>
  );
}

const assetsQuery = graphql`
  {
    heroImg: file(relativePath: { eq: "products/transaction-stream-bg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1248, placeholder: NONE, layout: FIXED)
      }
    }
    heroImgMobile: file(
      relativePath: { eq: "products/transaction-stream-bg-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 361, placeholder: NONE, layout: FIXED)
      }
    }
    cardEnrollImg: file(relativePath: { eq: "products/card-enroll.png" }) {
      childImageSharp {
        gatsbyImageData(width: 737, layout: CONSTRAINED)
      }
    }
  }
`;
