export default `{
  "accountId": "fd335eaf-6929-47a5-8c26-123456789abc",
  "amount": 5.44,
  "auth": true,
  "authCode": "01107I",
  "billingAmount": 5.44,
  "billingCurrency": "USD",
  "brand": {
    "name": "RITE AID"
  },
  "card": {
    "firstNumbers": "412345",
    "id": "167de7c4-b55c-458c-8698-123456789abc",
    "lastNumbers": "1234",
    "scheme": "visa",
    "metadata": {
        "userId": "abc123456"
    },
  },
  "cardPresent": true,  
  "cleared": false,
  "created": "2022-02-23T21:17:07.076Z",
  "currency": "USD",
  "datetime": "2022-02-23T21:17:03",
  "id": "b6a2c109-63a2-4eac-8209-123456789abc",
  "identifiers": {
    "amexApprovalCode": null,
    "mastercardAuthCode": null,
    "mastercardRefNumber": null,
    "mastercardTransactionSequenceNumber": null,
    "visaAuthCode": "01107I"
  },
  "location": {
    "countryCode": "USA",
    "postcode": "10128",
    "state": "NY"
  },
  "merchantCategoryCode": "5912",
  "merchantDescriptor": "RITE AID 04688",
  "programId": "9bb4351a-3ccc-4777-a658-123456789abc",
  "programType": "transaction-stream",
  "updated": "2022-02-23T21:17:07.076Z",
  "wallet": null
}`;
